import React, { useState } from "react";
import api from "../logic/apiHandler";
import Dimmer from "./Dimmer";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Helmet } from "react-helmet-async";
import { Icon, Button, Form, FormInput, FormGroup, Grid, GridColumn, Header, Label } from "semantic-ui-react";

export default function EmailEventsLog() {
  const [email, setEmail] = useState("");
  const [emailEvents, setEmailEvents] = useState([]);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputError, setInputError] = useState(null);
  const [displayedEmail, setDisplayedEmail] = useState("");

  async function handleSubmit() {
    try {
      setFormSubmitted(true);
      setDataLoaded(false);
      setError(null);
      setInputError(null);
      setEmailEvents(await api.metaData.emailEvents(encodeURIComponent(email)));
      setDisplayedEmail(email);
      setDataLoaded(true);
    } catch (e) {
      if (e.httpErrorCode === 400) {
        setInputError("Invalid email address");
        setError("Invalid email address");
      } else {
        setInputError(null);
        setError(e);
      }
      setDataLoaded(true);
    }
  }

  const columns = [
    {
      header: "Committee Name",
      accessorKey: "committeeName",
    },
    {
      header: "datetime of Event",
      accessorKey: "dateTimeOfEvent",
      cell: ({ row }) => formatDatetime(row.original.dateTimeOfEvent),
    },
    {
      header: "Trackable Event Type",
      accessorKey: "TrackableEventType",
      cell: ({ row }) =>
        getTrackableContent(
          row.original.TrackableEventType,
          row.original.TrackableEventValue,
          row.original.TrackableEventReason
        ),
    },
    {
      header: "First Sent Date",
      accessorKey: "FirstSentDate",
      cell: ({ row }) => new Date(row.original.FirstSentDate?.split("T")[0]).toLocaleDateString(),
    },
    {
      header: "From Name",
      accessorKey: "FromName",
      cell: ({ row }) => <a href={`mailto:${row.original.FromEmail}`}>{row.original.FromName}</a>,
    },
    {
      header: "Recipient List",
      accessorKey: "RecipientList",
    },
    {
      header: "Email Name",
      accessorKey: "EmailName",
      cell: ({ row }) => getEmailNameContent(row.original.EmailName, row.original.EmailTestCaseName),
    },
  ];

  function formatDatetime(datetime) {
    let [date, time] = datetime.split("T");
    date = new Date(date).toLocaleDateString();
    time = time.split(":");
    return `${date} ${time[0]}:${time[1]}`;
  }

  function getTrackableContent(type, value, reason) {
    let res;
    if (reason) {
      res = `${type} - ${reason}`;
    } else {
      res = type;
    }
    if (value) {
      return <a href={value}>{res}</a>;
    } else {
      return res;
    }
  }

  function getEmailNameContent(name, testCaseName) {
    if (name === testCaseName) {
      return name;
    } else {
      return `${name} - ${testCaseName}`;
    }
  }

  const table = useReactTable({
    columns,
    data: emailEvents,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>Email Events Log</title>
      </Helmet>
      <Header as="h2">Email events log</Header>
      <p>Last 6 months of detailed event clicks/opens/bounces</p>
      <Grid columns={2}>
        <GridColumn width={6}>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <FormInput placeholder="Email.........." onChange={(e) => setEmail(e.target.value)} />
              <Button onClick={handleSubmit} style={{ backgroundColor: "#FAA61A" }}>
                Get logs
              </Button>
              {inputError ? (
                <Label basic color="red" pointing="left">
                  {inputError}
                </Label>
              ) : (
                <></>
              )}
            </FormGroup>
          </Form>
        </GridColumn>
        <GridColumn floated="right" width={4}>
          {formSubmitted ? <div>Currently displaying logs for {displayedEmail}</div> : <></>}
        </GridColumn>
      </Grid>
      {!formSubmitted || inputError ? (
        <></>
      ) : (
        <Dimmer error={error} dataLoaded={dataLoaded}>
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} colSpan={header.colSpan}>
                      <div
                        className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === "asc"
                              ? "Sort ascending"
                              : header.column.getNextSortingOrder() === "desc"
                              ? "Sort descending"
                              : "Clear sort"
                            : undefined
                        }
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <Icon name="caret up" color="yellow" />,
                          desc: <Icon name="caret down" color="yellow" />,
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Dimmer>
      )}
    </div>
  );
}
