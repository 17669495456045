import React, { useEffect, useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import api from "../logic/apiHandler";
import { useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export default function VoteHistory() {
  const { areaID } = useParams();
  const [voteHistoryData, setVoteHistoryData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      setDataLoaded(false);
      setError(null);

      try {
        setVoteHistoryData(await api.seatData.voteHistorySummary(areaID));
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    })();
  }, []);

  const columns = [
    {
      header: "Code",
      accessorKey: "westminster_2024_code",
    },
    {
      header: "Polling District",
      accessorKey: "POLLING_DISTRICT",
    },
    {
      header: "Likely Errors",
      accessorKey: "likelyErrors",
    },
    {
      header: "Local Electorate",
      accessorKey: "localElectorate",
    },
    {
      header: "West Electorate",
      accessorKey: "westElectorate",
    },
    {
      header: "W2017a",
      accessorKey: "W2017a",
      cell: ({ row }) => tidyZeroPoints(row.original.W2017a),
    },
    {
      header: "W2019a",
      accessorKey: "W2019a",
      cell: ({ row }) => tidyZeroPoints(row.original.W2019a),
    },
    {
      header: "W2024a",
      accessorKey: "W2024a",
      cell: ({ row }) => tidyZeroPoints(row.original.W2024a),
    },
    {
      header: "L2019a",
      accessorKey: "L2019a",
      cell: ({ row }) => tidyZeroPoints(row.original.L2019a),
    },
    {
      header: "L2021a",
      accessorKey: "L2021a",
      cell: ({ row }) => tidyZeroPoints(row.original.L2021a),
    },
    {
      header: "L2022a",
      accessorKey: "L2022a",
      cell: ({ row }) => tidyZeroPoints(row.original.L2022a),
    },
    {
      header: "L2023a",
      accessorKey: "L2023a",
      cell: ({ row }) => tidyZeroPoints(row.original.L2023a),
    },
    {
      header: "L2024a",
      accessorKey: "L2024a",
      cell: ({ row }) => tidyZeroPoints(row.original.L2024a),
    },
    {
      header: "W2017",
      accessorKey: "W2017",
      cell: ({ row }) => tidyZeroPoints(row.original.W2017),
    },
    {
      header: "W2019",
      accessorKey: "W2019",
      cell: ({ row }) => tidyZeroPoints(row.original.W2019),
    },
    {
      header: "W2024",
      accessorKey: "W2024",
      cell: ({ row }) => tidyZeroPoints(row.original.W2024),
    },
    {
      header: "L2019",
      accessorKey: "L2019",
      cell: ({ row }) => tidyZeroPoints(row.original.L2019),
    },
    {
      header: "L2021",
      accessorKey: "L2021",
      cell: ({ row }) => tidyZeroPoints(row.original.L2021),
    },
    {
      header: "L2022",
      accessorKey: "L2022",
      cell: ({ row }) => tidyZeroPoints(row.original.L2022),
    },
    {
      header: "L2023",
      accessorKey: "L2023",
      cell: ({ row }) => tidyZeroPoints(row.original.L2023),
    },
    {
      header: "L2024",
      accessorKey: "L2024",
      cell: ({ row }) => tidyZeroPoints(row.original.L2024),
    },
  ];

  function tidyZeroPoints(num) {
    if (num === "0.0000") {
      return "0";
    } else {
      return num;
    }
  }
  const table = useReactTable({
    columns,
    data: voteHistoryData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()}>
                    {
                      <div className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <Icon name="caret up" color="yellow" />,
                          desc: <Icon name="caret down" color="yellow" />,
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    }
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
