import React from "react";
import { Label } from "semantic-ui-react";

export default function SeatLevelLabel({ label, floatright }) {
  function getColour(label) {
    switch (label.toLowerCase()) {
      case "held":
        return "yellow";
      case "target":
        return "red";
      case "advanced":
        return "red";
      case "mf-":
        return "grey";
      case "mf":
        return "green";
      case "mf+":
        return "green";
      case "<10k":
        return "grey";
      default:
        return "grey";
    }
  }

  function getTitle(label) {
    switch (label.toLowerCase()) {
      case "held":
        return "Held";
      case "target":
        return "Target";
      case "advanced":
        return "Adv";
      case "mf-":
        return "MF-";
      case "mf":
        return "MF+";
      case "mf+":
        return "MF+";
      case "<10k":
        return "<10K";
      default:
        return "";
    }
  }

  return (
    <Label color={getColour(label)} horizontal size="mini" className={floatright ? "right floated" : ""}>
      {getTitle(label)}
    </Label>
  );
}
