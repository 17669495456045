import React, { useEffect, useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import api from "../logic/apiHandler";
import { useParams } from "react-router-dom";
import Dimmer from "./Dimmer";
import { Helmet } from "react-helmet-async";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { Header, Icon } from "semantic-ui-react";

export default function WalkSummarisationTable({ areaName }) {
  const [walkData, setWalkData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(true);
  const [error, setError] = useState(null);
  const { areaID } = useParams();

  useEffect(() => {
    async function getWalkData() {
      setDataLoaded(false);
      setError(null);

      try {
        const data = await api.seatData.walkAreaSummary(areaID);
        setWalkData(getTableStats(data));
        setDataLoaded(true);
        setError(null);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getWalkData();
  }, [areaID]);

  function getTableStats(data) {
    return data.map((row) => {
      return {
        ...row,
        percentageWalksWithDeliverers:
          row.walks && row.walksWithDeliverers ? `${Math.floor((row.walksWithDeliverers / row.walks) * 100)}%` : "-",
        percentageDoorsWithDeliverers:
          row.doorCount && row.doorsWithDeliverer
            ? `${Math.floor((row.doorsWithDeliverer / row.doorCount) * 100)}%`
            : "-",
        doorsPerDeliverer: row.walks && row.doorCount ? Math.ceil(row.doorCount / row.walks) : "-",
        percentageLeafletsWithDeliverer:
          row.leafletCount && row.leafletsWithDeliverer
            ? `${Math.floor((row.leafletsWithDeliverer / row.leafletCount) * 100)}%`
            : "-",
        leafletsPerDeliverer: row.walks && row.leafletCount ? Math.floor(row.leafletCount / row.walks) : "-",
        percentagePDWithWalks:
          row.pollingDistrictsWithWalks && row.pollingDistricts
            ? `${Math.floor((row.pollingDistrictsWithWalks / row.pollingDistricts) * 100)}%`
            : "-",
      };
    });
  }

  const columns = [
    {
      header: "Polling District",
      accessorKey: "name",
      cell: ({ row }) => (
        <Link to={generatePath(`/seatData/${row.original.code}/walkSummary`)}>{row.original.name}</Link>
      ),
    },
    {
      header: "Polling Districts",
      accessorKey: "pollingDistricts",
    },
    {
      header: "Polling Districts with walks",
      accessorKey: "pollingDistrictsWithWalks",
    },
    {
      header: "Walks",
      accessorKey: "walks",
    },
    {
      header: "Walks with Deliverers",
      accessorKey: "walksWithDeliverers",
    },
    {
      header: "People",
      accessorKey: "personCount",
    },
    {
      header: "Doors",
      accessorKey: "doorCount",
    },
    {
      header: "Leaflets",
      accessorKey: "leafletCount",
    },
    {
      header: "Doors with Deliverers",
      accessorKey: "doorsWithDeliverer",
    },
    {
      header: "Leaflets with Deliverers",
      accessorKey: "leafletsWithDeliverer",
    },
    {
      header: "% Walks With Deliverer",
      accessorKey: "percentageWalksWithDeliverers",
    },
    {
      header: "% Doors With Deliverer",
      accessorKey: "percentageDoorsWithDeliverers",
    },
    { header: "Doors Per Deliverer", accessorKey: "doorsPerDeliverer" },
    {
      header: "% Leaflets With Deliverer",
      accessorKey: "percentageLeafletsWithDeliverer",
    },
    {
      header: "Leaflets Per Deliverer",
      accessorKey: "leafletsPerDeliverer",
    },
    {
      header: "% Polling Districts With Walks",
      accessorKey: "percentagePDWithWalks",
    },
  ];

  const table = useReactTable({
    columns,
    data: walkData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: "name",
          desc: false,
        },
      ],
    },
  });

  return (
    <div>
      <Helmet>
        <title>{areaName ?? ""} - Walk Summary</title>
      </Helmet>
      <Dimmer dataLoaded={dataLoaded} error={error}>
        <table style={{ marginTop: "10px" }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      <div
                        className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <Icon name="caret up" color="yellow" />,
                          desc: <Icon name="caret down" color="yellow" />,
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Dimmer>
    </div>
  );
}
