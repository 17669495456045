import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, GridColumn, List, ListItem, ListIcon, ListContent } from "semantic-ui-react";
import api from "../logic/apiHandler";

export default function CommitteeList() {
  const [committees, setCommittees] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setCommittees(await api.committeeData.list());
      } catch (e) {
        console.log("e", e);
      }
    })();
  }, []);

  function renderCommittees() {
    const listOptions = formatListOptions();
    return (
      <div style={{ columnCount: 3 }}>
        <List>{listOptions}</List>
      </div>
    );
  }

  function formatListOptions() {
    return committees.map(({ committeeID, committeeName }) => {
      return (
        <ListItem key={committeeID}>
          <ListIcon name="lineGraph" />
          <ListContent>
            <Link to={committeeID.toString()}>{committeeName}</Link>
          </ListContent>
        </ListItem>
      );
    });
  }

  return (
    <div>
      <h3>Select a committee:</h3>
      {renderCommittees()}
    </div>
  );
}
