import React, { useMemo, useState, useEffect } from "react";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { formatTableData, getTableColumns } from "../logic/geCanvassDataProcessor";

export default function ChesterFormulaPercentagesTable({ data }) {
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(formatTableData(data));
    setColumns(getTableColumns(data));
  }, [data]);

  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <h3 className="title">Month by month data</h3>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>;
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  if (cell.row.id === "9" || cell.row.id === "10" || typeof cell.getValue() === "string") {
                    return <td key={cell.id}>{cell.getValue()}</td>;
                  } else {
                    return <td key={cell.id}>{Number.isNaN(cell.getValue()) ? `-` : `${cell.getValue()}%`}</td>;
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
