import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Surface, Symbols, ResponsiveContainer } from "recharts";
import { getMyCampaignGraphingData, getQuarterDates } from "../logic/myCampaignDataProcessor";
import api from "../logic/apiHandler";
import { Helmet } from "react-helmet-async";
import Dimmer from "./Dimmer";
import { Grid, GridColumn, Label, Header } from "semantic-ui-react";

export default function MyCampaignChart() {
  const [disabledLines, setDisabledLines] = useState([]);
  const [myCampaignData, setMyCampaignData] = useState(null);
  const [quarterDateTicks, setQuarterDateTicks] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [lineDetails] = useState([
    { dataKey: "totalRecordCount", colour: "#2C0BE6", axis: "left", enabled: true },
    { dataKey: "unnamed", colour: "#E60B0B", axis: "right", enabled: true },
    { dataKey: "nonGeocoded", colour: "#575757", axis: "right", enabled: true },
    { dataKey: "addressedNonGeocoded", colour: "#FDBB30", axis: "right", enabled: true },
    { dataKey: "linked", colour: "#742881", axis: "right", enabled: true },
    { dataKey: "localised", colour: "#FF10F0", axis: "right", enabled: true },
    { dataKey: "linkedAndLocalised", colour: "#5ec3f2", axis: "right", enabled: true },
    { dataKey: "hasContact", colour: "#360370", axis: "right", enabled: true },
    { dataKey: "hasEmail", colour: "#2a730d", axis: "right", enabled: true },
    { dataKey: "hasPhone", colour: "#195c4b", axis: "right", enabled: true },
    { dataKey: "postcodeOnly", colour: "#6e2904", axis: "right", enabled: true },
    { dataKey: "hasDistricts", colour: "#bf2a52", axis: "right", enabled: true },
  ]);

  useEffect(() => {
    async function getMyCampaignData() {
      setDataLoaded(false);
      setError(null);

      try {
        let data = await api.metaData.myCampaignStats();
        data = getMyCampaignGraphingData(data);
        setMyCampaignData(data);
        setQuarterDateTicks(getQuarterDates(data));
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getMyCampaignData();
  }, []);

  function renderLegend() {
    return lineDetails.map((line) => {
      let name = line.dataKey.split(/(?<![A-Z])(?=[A-Z])/).join(" ");
      name = name.charAt(0).toUpperCase() + name.slice(1);
      return (
        <div style={{ opacity: line.enabled ? 1 : 0.5 }}>
          <Label circular style={{ backgroundColor: line.colour }} onClick={() => toggleLine(line.dataKey)} />
          {name}
        </div>
      );
    });
  }

  function toggleLine(dataKey) {
    let line = lineDetails.find((lineDetail) => lineDetail.dataKey === dataKey);
    line.enabled = !line.enabled;
    if (disabledLines.includes(dataKey)) {
      setDisabledLines(disabledLines.filter((obj) => obj !== dataKey));
    } else {
      setDisabledLines([...disabledLines, dataKey]);
    }
  }

  function renderLines() {
    return lineDetails.map((line) => {
      return (
        <Line
          yAxisId={line.axis}
          type="monotone"
          dataKey={line.dataKey}
          key={line.dataKey}
          dot={false}
          stroke={line.colour}
          isAnimationActive={false}
          style={{ opacity: line.enabled ? 1 : 0 }}
        />
      );
    });
  }

  function formatDateTick(value) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let date = new Date(value);
    return `${months[date.getMonth()]} ${String(date.getFullYear()).substring(2)}`;
  }

  return (
    <div>
      <Helmet>
        <title> My Campaign Chart</title>
      </Helmet>
      <Header as="h2">My Campaign stats</Header>
      <Dimmer error={error} dataLoaded={dataLoaded}>
        <Grid>
          <GridColumn width={9}>
            <LineChart
              width={1000}
              height={500}
              data={myCampaignData}
              margin={{
                bottom: 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 7" />
              <XAxis
                dataKey="date"
                ticks={quarterDateTicks}
                type="category"
                tickFormatter={(value) => formatDateTick(value)}
              />
              <YAxis
                yAxisId="left"
                tickCount={10}
                width={80}
                domain={[0, (dataMax) => Math.round((dataMax + 2000) / 2000) * 2000]}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                tickFormatter={(value) => {
                  return `${value}%`;
                }}
              />
              {/* <Legend verticalAlign="top" payload={lineDetails} content={renderLegend} /> */}
              {renderLines()}
            </LineChart>
          </GridColumn>
          <GridColumn width={2}>{renderLegend()}</GridColumn>
        </Grid>
      </Dimmer>
    </div>
  );
}
