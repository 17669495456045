import React, { useMemo, useState, useEffect } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useParams } from "react-router-dom";
import api from "../logic/apiHandler";
import { Helmet } from "react-helmet-async";
import { Icon } from "semantic-ui-react";
import Dimmer from "./Dimmer";

export default function QuestionsListTable() {
  const [activistData, setActivistData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const { areaID } = useParams();

  useEffect(() => {
    async function getActivistData() {
      setDataLoaded(false);
      setError(null);

      try {
        setActivistData(await api.seatData.activistCodes(areaID));
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getActivistData();
  }, [areaID]);

  const columns = useMemo(() => [
    {
      header: "Code Type",
      accessorKey: "ActivistCodeType",
    },
    {
      header: "Code Name",
      accessorKey: "ActivistCodeName",
    },
    {
      header: "Weight",
      accessorKey: "weight",
    },
    {
      header: "Recent Count",
      accessorKey: "recentCount",
    },
  ]);

  const table = useReactTable({
    columns,
    data: activistData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>Activist Codes</title>
      </Helmet>

      <Dimmer dataLoaded={dataLoaded} error={error}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div
                      className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Dimmer>
    </div>
  );
}
