import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "./UserContext";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, List, ListItem, Checkbox, ListIcon, ListContent } from "semantic-ui-react";
import SeatLevelLabel from "./SeatLevelLabel";

export default function ConstituencySelectionScreen() {
  const [constituencies, setConstituencies] = useState({});
  const [activeAreaType, setActiveAreaType] = useState("westminster2024");
  const [targetOnly, setTargetOnly] = useState(true);
  const user = useContext(UserContext);

  useEffect(() => {
    setConstituencies(user?.allowedConstituencies ?? {});
  }, [user]);

  function renderConstituencies() {
    if (constituencies[activeAreaType]) {
      let constituencyList = constituencies[activeAreaType];
      if (targetOnly) {
        let targetOnlyList = constituencyList.filter((constituency) => constituency.label !== null);
        if (targetOnlyList.length !== 0) {
          constituencyList = targetOnlyList;
        }
      }

      const listOptions = formatListOptions(constituencyList);
      return (
        <div style={{ columnCount: 3 }}>
          <List>{listOptions}</List>
        </div>
      );
    }
  }

  function formatListOptions(constituencyList) {
    return constituencyList.map((constituency) => {
      const tag = constituency.label ? <SeatLevelLabel label={constituency.label.toLowerCase()} /> : null;
      return (
        <ListItem key={constituency.code}>
          <ListIcon className="lineGraph" />
          <ListContent>
            <Link to={constituency.code}>{constituency.name}</Link>&nbsp;
            {tag}
          </ListContent>
        </ListItem>
      );
    });
  }

  function getAreaTypeButtons() {
    const links = Object.keys(constituencies).map((areaType) => {
      let areaName = areaType.charAt(0).toUpperCase() + areaType.slice(1);
      areaName = areaName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
      if (areaName !== "Westminster" && constituencies[areaType].length > 0) {
        return (
          <Button
            key={areaType}
            onClick={() => setActiveAreaType(areaType)}
            color={activeAreaType === areaType ? "yellow" : null}
          >
            {areaName}
          </Button>
        );
      }
    });
    return links;
  }

  return (
    <div>
      {console.log("render")}
      View by: <ButtonGroup>{getAreaTypeButtons()}</ButtonGroup> &nbsp;&nbsp;&nbsp;&nbsp; Show targets only&nbsp;&nbsp;
      <Checkbox className="mini" toggle checked={targetOnly} onChange={(e, data) => setTargetOnly(data.checked)} />{" "}
      {renderConstituencies()}
    </div>
  );
}
