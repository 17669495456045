import React, { useEffect } from "react";
import {
  Dimmer,
  DimmerDimmable,
  Header,
  PlaceholderHeader,
  PlaceholderLine,
  PlaceholderParagraph,
  Loader,
  Segment,
  Image,
  Placeholder,
} from "semantic-ui-react";

export default function CustomDimmer({ dataLoaded, error, children }) {
  function getErrorMessage(error) {
    let code = error?.httpErrorCode?.toString() ?? "";
    if (code === "403") {
      return "Invalid user authorisation";
    } else if (code === "404") {
      return "Resource not found";
    } else if (code.startsWith("4")) {
      return "Client error - please check details and try again";
    } else if (code.startsWith("5")) {
      return `Server error - please wait a few minutes before trying again`;
    } else {
      return error.errorMessage;
    }
  }

  return (
    <div className="loader-container">
      <Dimmer className="light" active={!dataLoaded || error}>
        <Loader active={!dataLoaded} content="Loading" style={{ color: "#FAA61A" }} />
      </Dimmer>
      {error ? (
        <div>
          <Header as="h2" style={{ color: "white" }}>
            {error?.httpErrorCode}
          </Header>
          {getErrorMessage(error)}
        </div>
      ) : (
        children
      )}
    </div>
  );
}
